export const supportsObjectFit = () => {
  if (typeof document === `undefined`) {
    return true;
  }
  return "objectFit" in document.documentElement.style;
};

export const supportsSrcSet = () => {
  if (typeof document === `undefined`) {
    return true;
  }
  return "srcset" in document.createElement("img");
};

export const isMobile = () => {
  if (typeof window === `undefined`) {
    return false
  }
  return window.innerWidth < 768
}

export const supportsWebP = () => {
  if (!window.createImageBitmap) {
    return Promise.resolve(false);
  }

  const webpdata =
    "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";

  return fetch(webpdata)
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      return createImageBitmap(blob).then(
        () => Promise.resolve(true),
        () => Promise.resolve(false)
      );
    });
};

export function parsePath(path) {
  var pathname = path || `/`
  var search = ``
  var hash = ``

  var hashIndex = pathname.indexOf(`#`)
  if (hashIndex !== -1) {
    hash = pathname.substr(hashIndex)
    pathname = pathname.substr(0, hashIndex)
  }

  var searchIndex = pathname.indexOf(`?`)
  if (searchIndex !== -1) {
    search = pathname.substr(searchIndex)
    pathname = pathname.substr(0, searchIndex)
  }

  return {
    pathname: pathname,
    search: search === `?` ? `` : search,
    hash: hash === `#` ? `` : hash,
  }
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
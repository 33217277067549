import React from "react";

import Moment from "react-moment";
import "moment-timezone";

const Clock = props => {
  const dateToFormat = "hh:mm:ss a";
  const timestamp = props.timestamp || "EST";
  const timezone = props.timezone || "America/New_York";
  return (
    <div className="timer">
      <span>
        <Moment tz={timezone} format={dateToFormat} interval={1000}></Moment>{" "}
        {timestamp}
      </span>
    </div>
  );
};

export default Clock;

import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Clock from "./clock";
import FooterMenu from "./footer-menu";
import FooterLinks from "./footer-links";
import { TextInput } from "./forms";
import caretSrc from "../assets/images/caret.svg";
import logoSrc from "../assets/images/footer-text.svg";

const Footer = ({
  siteName,
  footerSuccessMessage,
  footerTitle,
  klaviyoListId,
}) => {
  const inputRef = useRef(null);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const [reveal, setReveal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReveal(true);
    }, 1000);
  }, []);

  const onSubmit = async (data) => {
    const options = {
      method: "POST",
      headers: { revision: "2023-02-22", "content-type": "application/json" },
      body: JSON.stringify({
        data: {
          type: "subscription",
          attributes: {
            list_id: klaviyoListId ?? "Tv6y7D",
            custom_source: "HH Agency Website",
            email: data.workEmail ?? "",
          },
        },
      }),
    };

    const res = await fetch(
      "https://a.klaviyo.com/client/subscriptions/?company_id=VqaG83",
      options
    );
    setIsSuccess(res?.ok ?? false);

    setTimeout(() => {
      if (isSuccess) return;

      setIsSuccess(false);
      reset({ workEmail: "" });

      inputRef?.current?.blur();
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 3000);
  };

  return (
    <footer
      className={`footer gs-animate ${reveal ? "is-revealed" : ""}`}
      role="contentinfo"
    >
      <div className="container container--bordered container--bottom-bordered">
        <h2 className="h1 indent-text complex-heading footer-heading">
          <div className="title-link">
            <sup>Newsletter</sup>
            {footerTitle}
          </div>
        </h2>

        <form onSubmit={handleSubmit(onSubmit)} className="form-newsletter">
          <div className="form-group">
            <TextInput
              innerRef={inputRef}
              type="email"
              label="Enter your email"
              name="workEmail"
              register={register}
              errors={errors}
              validation={{
                required: "This field is required",
                onChange: () => {
                  if (errors.workEmail) {
                    clearErrors("workEmail");
                  }
                },
                onBlur: () => {
                  if (errors.workEmail) {
                    clearErrors("workEmail");
                  }
                },
              }}
            />
            <button className="form-newsletter__button" aria-label="Sign Up">
              <img src={caretSrc} alt="" />
            </button>
          </div>

          <div className="form-newsletter__success">
            {isSuccess && (
              <p>
                {footerSuccessMessage ??
                  "Thank you! Your submission has been received!"}
              </p>
            )}
          </div>
        </form>
      </div>

      <div className="container container--bordered">
        <span className="footer__logo">
          <img src={logoSrc} alt="logo" title={siteName} />
        </span>
        <div className="footer__wrap">
          <FooterMenu />
          <div className="flex-item">
            <Clock />
            <FooterLinks />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";

const FooterLinks = () => (
  <>
    <nav role="navigation">
      <ul
        className="footer__links listreset"
        itemScope
        itemType="http://schema.org/ItemList"
      >
        <li itemProp="itemListElement">
          <a
            href="https://twitter.com/halfhelix?lang=en"
            tabIndex="0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </li>
        <li itemProp="itemListElement">
          <a
            href="https://github.com/halfhelix"
            tabIndex="0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
        </li>
        <li itemProp="itemListElement">
          <a
            href="https://www.instagram.com/halfhelix/?hl=en"
            tabIndex="0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </li>
        <li itemProp="itemListElement">
          <a
            href="mailto:&#105;&#110;&#102;&#111;&#064;&#104;&#097;&#108;&#102;&#104;&#101;&#108;&#105;&#120;&#046;&#099;&#111;&#109;"
            tabIndex="0"
          >
            &#105;&#110;&#102;&#111;&#064;&#104;&#097;&#108;&#102;&#104;&#101;&#108;&#105;&#120;&#046;&#099;&#111;&#109;
          </a>
        </li>
      </ul>
    </nav>
  </>
);

export default FooterLinks;

import React from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import { TweenLite } from "gsap";
import { Linear } from "gsap/EasePack";
import { parsePath } from "js/util";

class Link extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeClassName: null,
    };

    this.timeout = false;
  }

  componentDidMount() {
    if (this.props.activeClassName) {
      this.timeout = setTimeout(() => {
        this.setState({
          activeClassName: this.props.activeClassName,
        });
      }, 200);
    }

    if (window && this.props.to && parsePath(this.props.to).pathname) {
      window.___loader.enqueue(parsePath(this.props.to).pathname);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { children, activeClassName, to, ...props } = this.props;
    const isQuick = false;

    return ~(to || "").indexOf("mailto:") || to.includes("https:") ? (
      <a href={to} {...props}>
        {children}
      </a>
    ) : (
      <TransitionLink
        {...props}
        to={to}
        activeClassName={this.state.activeClassName}
        exit={{
          trigger({ node }) {
            TweenLite.fromTo(
              node,
              0.5,
              { opacity: 1 },
              { opacity: 0, autoCSS: true, ease: Linear.none }
            );
          },
          length: 0.5,
        }}
        entry={{
          trigger({ node }) {
            TweenLite.fromTo(
              node,
              1,
              { opacity: 0 },
              { opacity: 1, autoCSS: true, ease: Linear.none }
            );
          },
          delay: isQuick ? 0 : 0.6,
          length: isQuick ? 1 : 1,
        }}
      >
        {children}
      </TransitionLink>
    );
  }
}

export default Link;

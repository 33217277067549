import PropTypes from "prop-types";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Link from "./link";

const HeaderMenu = ({ siteName, currentPage }) => {
  const data = useStaticQuery(graphql`
    query {
      takeshape {
        getSetting {
          header {
            enabled
            link
            title
            hasChild
          }
        }
      }
    }
  `);
  return (
    <div className="main-nav">
      <Link
        to="/"
        className="main-nav__logo"
        tabIndex="0"
        activeClassName="active-link"
      >
        {siteName}
      </Link>
      <nav role="navigation">
        <ul
          className="main-nav__list listreset"
          itemScope
          itemType="http://schema.org/ItemList"
        >
          {data.takeshape.getSetting.header.map((item, idx) =>
            item.hasChild ? <li className={`main-nav__list-item ${!item.enabled && 'hide'}`} itemProp="itemListElement" key={idx}>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb listreset flex-item">
                <li className="breadcrumb__item">
                  <Link to={item.link} tabIndex="0" activeClassName="active-link">
                    {item.title}
                  </Link>
                </li>
                {currentPage && (
                  <li className="breadcrumb__item active" aria-current="page">
                    {currentPage}
                  </li>
                )}
              </ol>
            </nav>
          </li> : <li className={`main-nav__list-item ${!item.enabled && 'hide'}`} itemProp="itemListElement" key={idx}>
            <Link
              to={item.link}
              tabIndex="0"
              activeClassName="active-link">
              {item.title}
            </Link>
          </li>
          )}
        </ul>
      </nav>
    </div>
  )};

HeaderMenu.propTypes = {
  siteName: PropTypes.string
};

HeaderMenu.defaultProps = {
  siteName: ``
};

export default HeaderMenu;

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import Footer from "./footer";

import "../styles/global.scss";

const siteName = "Half Helix";

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      takeshape {
        getSetting {
          seoContent
          footerSuccessMessage
          footerTitle
          klaviyoListId
          footer {
            enabled
            link
            title
          }
        }
      }
    }
  `);

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        siteName={siteName}
        headerClass={props.headerClass}
        breadcrumbClass={props.breadcrumbClass}
        currentPage={props.currentPage}
      />
      <main>{props.children}</main>
      <Footer
        siteName={siteName}
        footerSuccessMessage={data.takeshape.getSetting.footerSuccessMessage}
        footerTitle={data.takeshape.getSetting.footerTitle}
        klaviyoListId={data.takeshape.getSetting.klaviyoListId}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerClass: PropTypes.string,
  breadcrumbClass: PropTypes.string,
  currentPage: PropTypes.string,
};

export default Layout;

import React, { useState } from "react";

const Select = ({
  label,
  name,
  options = [],
  onChange,
  selectedValue,
  errors,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasError = errors && !!errors?.[name];

  const handleChange = (e, value) => {
    e.preventDefault();
    onChange(value);
    setIsOpen(false);
  };

  const handleBlur = ({ currentTarget, relatedTarget }) =>
    !currentTarget.contains(relatedTarget) && setIsOpen(false);

  return (
    <div>
      <div
        className={`form-floating form-select ${
          !!selectedValue ? "has-value" : ""
        } ${isOpen ? "is-active" : ""}`}
        onBlur={handleBlur}
        role="select"
      >
        <button
          type="button"
          className={`form-select__button ${hasError ? "is-invalid" : ""}`}
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          {selectedValue}
        </button>
        <ul className="form-select__dropdown">
          {options.map(({ name, value }) => (
            <li key={value} value={value}>
              <button
                type="button"
                className={`${selectedValue === value ? "is-selected" : ""}`}
                onClick={(e) => handleChange(e, value)}
              >
                {name}
              </button>
            </li>
          ))}
        </ul>

        {label && <label htmlFor={name}>{label}</label>}
      </div>

      {hasError && (
        <span className="form-error">{errors?.[name]?.message}</span>
      )}
    </div>
  );
};

export default Select;

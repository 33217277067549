import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import HeaderMenu from "../components/header-menu";
import Clock from "./clock";
import darkLogoSrc from "../assets/images/halfhelix-logo-dark.svg";
import mediumLogoSrc from "../assets/images/halfhelix-logo-medium.svg";

const Header = ({
  siteTitle,
  siteName,
  headerClass,
  breadcrumbClass,
  currentPage,
}) => {
  const data = useStaticQuery(graphql`
    query {
      Image1: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: FIXED)
        }
      }
      Image2: file(relativePath: { eq: "logo-dark.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: FIXED)
        }
      }
    }
  `);

  const containerClass = headerClass
    ? "container " + headerClass
    : "container ";
  return (
    <header className={headerClass} role="banner">
      <div className={containerClass}>
        <div className="nav-bar">
          <div className={breadcrumbClass}>
            <HeaderMenu siteName={siteName} currentPage={currentPage} />
          </div>
          <div className="nav-bar__time-wrap">
            <Clock />
            <span
              className="helix"
              itemScope
              itemType="http://schema.org/ImageObject"
            >
              <GatsbyImage
                image={data.Image1.childImageSharp.gatsbyImageData}
                className="helix__logo"
                alt="logo"
                itemProp="contentUrl"
              />
              <img
                className="helix__logo--dark"
                itemProp="contentUrl"
                src={darkLogoSrc}
                alt={siteName}
              />
              <img
                className="helix__logo--medium"
                itemProp="contentUrl"
                src={mediumLogoSrc}
                alt={siteName}
              />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

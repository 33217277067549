import React from "react";

const TextInput = ({
  type = "text",
  label,
  name,
  register,
  validation,
  errors,
  innerRef,
  ...props
}) => {
  const hasError = errors && !!errors?.[name];

  return (
    <div>
      <div className="form-floating">
        <input
          className={`form-control ${hasError ? "is-invalid" : ""}`}
          type={type}
          placeholder={name}
          aria-label={name}
          ref={innerRef && innerRef}
          {...register(name, validation)}
          {...props}
        />
        {label && <label htmlFor={name}>{label}</label>}
      </div>
      {hasError && (
        <span className="form-error">{errors?.[name]?.message}</span>
      )}
    </div>
  );
};

export default TextInput;

import React from "react";
import Link from "./link";
import { useStaticQuery, graphql } from "gatsby";

const FooterMenu = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      takeshape {
        getSetting {
          footer {
            enabled
            link
            title
          }
        }
      }
    }
  `);

  return (
    <nav className="footer__menu-wrap" role="navigation">
      <ul
        className="footer__menu listreset"
        itemScope
        itemType="http://schema.org/ItemList"
      >
        {data.takeshape.getSetting.footer.map((item, idx) => (
          <li
            className={`${!item.enabled && "hide"}`}
            itemProp="itemListElement"
            key={idx}
          >
            <Link to={item.link} tabIndex="0">
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FooterMenu;
